export interface finish {
    name: string;
    colourHex: string;
    imageFolder: string;
}

export const finishes: finish[] = [
    { name: 'Matt Black', colourHex: '#333333', imageFolder: 'MATTBLACK' },
    { name: 'Brushed Steel', colourHex: '#d9d9d9', imageFolder: 'BRUSHEDSTEEL' },
    { name: 'Pearlescent White', colourHex: '#f5f5f5', imageFolder: 'PEARLESCENTWHITE' },
    { name: 'Polished Copper', colourHex: '#e38d6b', imageFolder: 'POLISHEDCOPPER' },
    { name: 'Satin Brass', colourHex: '#c2b798', imageFolder: 'SATINBRASS' },
    { name: 'Matt Grey', colourHex: '#575756', imageFolder: 'MATTGREY' },
    { name: 'Matt Blue', colourHex: '#1f3971', imageFolder: 'MATTBLUE' },
    { name: 'Black Chrome', colourHex: '#1d1d1b', imageFolder: 'BLACKCHROME' },
];
