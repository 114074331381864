import { HexColorPicker } from "react-colorful";
import { FaPalette } from '@react-icons/all-files/fa/FaPalette';
import React from 'react';

import '../styles/BackgroundSelector.scss';

export interface BackgroundSelectorProps {
    setBackgroundColour: React.Dispatch<React.SetStateAction<string>>;
    backgroundColour: string;
}


const BackgroundSelector = (props: BackgroundSelectorProps) => {

    return (
        <div className='product-config-background'>
            <h3>BACKGROUND</h3>
            <div className='color-picker'>
                <HexColorPicker
                    color={props.backgroundColour}
                    onChange={props.setBackgroundColour}
                />
            </div>
            <p>
                USE THE BACKGROUND COLOUR<br />SELECTOR TO MATCH YOUR DECOR
            </p>
            <div style={{ clear: 'both' }}></div>
        </div>
    );
};

export default BackgroundSelector;