import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { useScreenshot, createFileName } from 'use-react-screenshot';

import FinishSelector from '../components/FinishSelector';
import BackgroundSelector from "../components/BackgroundSelector";
import ProductSelector from "../components/ProductSelector";
import ProductPreview from "../components/ProductPreview";
import RotarySelector from "../components/RotarySelector";

import '../styles/index.scss';
import "@fontsource/roboto-flex/variable-full.css";
import ImageSaver from '../components/ImageSaver';
import ContactUs from '../components/ContactUs';

// markup
const IndexPage = () => {
  const [backgroundColour, setBackgroundColour] = React.useState('#8d858e');
  const [productFamily, setProductFamily] = React.useState('MATTBLACK');
  const [selectedImage, setSelectedImage] = React.useState('PCDMB22UAC30B');
  const [image, takeScreenshot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0
  });

  const productPreviewRef = React.createRef<HTMLDivElement>();

  const download = (image: any) => {
    const a = document.createElement('a');
    a.href = image;
    a.download = createFileName('jpg', selectedImage);
    a.click();
  };

  const downloadScreenshot = () => takeScreenshot(productPreviewRef.current).then(download);

  return (
    <main>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>BG EVOLVE - Configurator</title>
      </Helmet>

      <div className='header-container'>
        <div className='header-logo'>
          <StaticImage
            alt='BG EVOLVE Logo'
            src='../images/EVOLVE-LOGO.svg'
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              inset: 0,
            }}
          />
        </div>
        <div className='header-title'><h1>CONFIGURATOR</h1></div>
      </div>
      <div className='body-container'>
        <div className='product-preview'>
          <ProductPreview previewRef={productPreviewRef} selectedImage={selectedImage} backgroundColour={backgroundColour} selectedFamily={productFamily} />
        </div>
        <div className='product-config'>
          <FinishSelector setProductFamily={setProductFamily} setSelectedProduct={setSelectedImage} />
          <BackgroundSelector setBackgroundColour={setBackgroundColour} backgroundColour={backgroundColour} />
          <ProductSelector />
        </div>
        <div className='product-selector'>
          <RotarySelector selectedProductFamily={productFamily} selectImage={setSelectedImage} />
        </div>
      </div>
      <div className='image-save'>
        <ImageSaver saveImage={downloadScreenshot} />
      </div>
      <div className='footer-container'>
        <ContactUs />
      </div>
    </main>
  )
};

export default IndexPage;