import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

import useProductImages from '../data/ProductImages';

import '../styles/ProductPreview.scss';
import { Color } from '@react-types/color';
import { productDescription } from '../data/ProductData';
import { finishes } from '../data/ProductFinish';


export interface ProductPreviewProps {
    selectedImage: string;
    selectedFamily: string;
    backgroundColour: Color;
    previewRef: React.RefObject<HTMLDivElement>;
}

const ProductPreview = (props: ProductPreviewProps) => {
    const data = useProductImages();
    const description = productDescription[props.selectedImage];
    const family = finishes.find(f => f.imageFolder === props.selectedFamily);

    const selectedImageSrc = data.allFile.edges.find((f: any) => f.node.name === props.selectedImage);

    return (
        <div className='product-preview-container' ref={props.previewRef}>
            <div style={{ backgroundColor: props.backgroundColour.toString('css') }} className='product-preview-image'>
                <img src={selectedImageSrc.node.publicURL} />
            </div>
            <div className='product-preview-title-container'>
                <div className='product-preview-title'>
                    <span className='family'>{family?.name.toUpperCase()} </span>
                    <span className='product'>/ {description} </span>
                    <span className='productCode'>/ {props.selectedImage}</span>
                </div>
            </div>
        </div>
    );
};

export default ProductPreview;
