import React from 'react';
import { FaDownload } from '@react-icons/all-files/fa/FaDownload';
import { FaAngleRight } from '@react-icons/all-files/fa/FaAngleRight';

import '../styles/ImageSaver.scss';

export interface ImageSaverProps {
    saveImage: () => void;
};

const ImageSaver = ({ saveImage }: ImageSaverProps) => {
    function saveSelection() {
        saveImage();
    }

    return (
        <div className='save-container'>
            <div className='blurb'>
                <h4>If you would like more colours and finishes...</h4>
                <p>We can supply almost any colour and finish on orders over 500 pieces</p>
                <p>A full list of RAL Colours can be viewed <a target='_blank' href='https://en.wikipedia.org/wiki/List_of_RAL_colors'>HERE</a></p>
            </div>
            <div className='save-image'>
                <div className='save-image-icon'>
                    <FaDownload size={35} />
                </div>
                <div className='save-image-button-container'>
                    <div className='save-image-button' onClick={(e) => saveSelection()}>
                        <span>SAVE YOUR SELECTION</span>
                        <FaAngleRight size={30} />
                        <FaAngleRight size={20} />
                    </div>
                    <p>Click to save your selection as an image</p>
                </div>
            </div>
            <div className='save-footer'>
            </div>
        </div>
    );
};

export default ImageSaver;