import React, { ChangeEvent, useState, createRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FaSpinner } from '@react-icons/all-files/fa/FaSpinner';
import email from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { styled } from '@mui/system';
import { MenuItem } from '@mui/material';

import '../styles/ContactUs.scss';

const inputStyle: React.CSSProperties = {
    backgroundColor: 'white',
    borderRadius: '2px',
    margin: '2px 5px 2px 5px',
};

const CustomFormField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#3AA3DC',
    }
});

const businessTypes = [
    'Home User',
    'Interior Designer',
    'Electrical Contractor',
    'Home Builder',
    'Wholesaler / Retailer'
];

export interface ContactUsState {
    name?: string;
    email?: string;
    postcode?: string;
    company?: string;
    position?: string;
    comments?: string;

    isSending?: boolean;
    errors: { [name: string]: boolean };
}


const ContactUs = () => {
    const recaptchaRef = createRef<ReCAPTCHA>();
    const recaptchaSiteKey = '6LdfcwshAAAAAC189hdm7nz39TxsU5rHB9ZYC-JY';
    const [captchaStatus, setCaptchaStatus] = useState<boolean>(false);

    const [state, setState] = useState<ContactUsState>({
        name: '',
        email: '',
        postcode: '',
        company: '',
        position: '',
        comments: '',

        isSending: false,
        errors: {}
    });

    const isSending = () => state.isSending;

    function handleInputChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setState({
            ...state,
            [name]: value
        });
    }

    function resetForm() {
        recaptchaRef.current?.reset();
        setCaptchaStatus(false);
        setState({
            ...state,
            isSending: false
        });
    }

    function validateForm(): boolean {
        var tempErrors: { [name: string]: boolean } = {};

        if (state.name === '') tempErrors['name'] = true;
        if (state.email === '') tempErrors['email'] = true;
        if (state.postcode === '') tempErrors['postcode'] = true;
        if (state.company === '') tempErrors['company'] = true;
        if (state.comments === '') tempErrors['comments'] = true;

        setState({
            ...state,
            errors: tempErrors
        });

        if (Object.keys(tempErrors).length > 0) return false;

        return true;
    }

    function sendContactForm() {
        if (!validateForm()) return;

        setState({
            ...state,
            isSending: true
        });

        const serviceId = 'service_k3qf535';
        const templateId = 'template_jo7gwqq';
        const publicKey = '_Pe-C6wwiZO-kaT5W';
        const recaptchaToken = recaptchaRef.current?.getValue();
        const templateData = { ...state, 'g-recaptcha-response': recaptchaToken };

        console.log(templateData);

        email.send(serviceId, templateId, templateData, publicKey).then((response) => {
            console.log('Email sent!', response.status, response.text);
            resetForm();

            window.location.reload();
        }, (err) => {
            console.log('Email failed...', err);
            resetForm();
        });
    }

    function captchaPassed(value: string | null) {
        if (value) {
            setCaptchaStatus(true);
        } else {
            setCaptchaStatus(false);
        }
    }

    const SubmitIcon: React.FC = (): React.ReactElement => {
        if (isSending()) {
            return (<FaSpinner className='spinning-icon' />);
        } else {
            return (<></>);
        }
    }

    return (
        <div className='contact-container'>
            <div className='logo'>
                <StaticImage
                    alt='BG EVOLVE Logo'
                    src='../images/EVOLVE-LOGO.svg'
                    style={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        inset: 0,
                    }}
                />
            </div>
            <div className='form'>
                <CustomFormField
                    name='name'
                    id='name'
                    label='Name'
                    variant='filled'
                    size='small'
                    InputLabelProps={{ shrink: true }}
                    margin='none'
                    required
                    autoComplete='none'
                    style={inputStyle}
                    value={state.name}
                    onChange={handleInputChange}
                    InputProps={{ disableUnderline: true }}
                    {...(state.errors["name"] && { error: true })}
                />

                <CustomFormField
                    name='email'
                    id='email'
                    label='Email'
                    variant='filled'
                    size='small'
                    margin='none'
                    required
                    autoComplete='none'
                    InputLabelProps={{ shrink: true }}
                    style={inputStyle}
                    value={state.email}
                    onChange={handleInputChange}
                    InputProps={{ disableUnderline: true }}
                    {...(state.errors["email"] && { error: true })}
                />

                <CustomFormField
                    name='postcode'
                    id='postcode'
                    label='Postcode'
                    size='small'
                    variant='filled'
                    margin='none'
                    required
                    autoComplete='none'
                    InputLabelProps={{ shrink: true }}
                    style={inputStyle}
                    value={state.postcode}
                    onChange={handleInputChange}
                    InputProps={{ disableUnderline: true }}
                    {...(state.errors["postcode"] && { error: true })}
                />

                <CustomFormField
                    name='company'
                    id='company'
                    select
                    label='Business/User Type'
                    value={state.company}
                    onChange={handleInputChange}
                    variant='filled'
                    margin='none'
                    size='small'
                    required
                    autoComplete='none'
                    InputLabelProps={{ shrink: true }}
                    style={inputStyle}
                    InputProps={{ disableUnderline: true }}
                    sx={{ height: '41px' }}
                    {...(state.errors["company"] && { error: true })}
                >
                    <MenuItem disabled value=''>Business/User Type</MenuItem>
                    {businessTypes.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </CustomFormField>

                <CustomFormField
                    name='position'
                    id='position'
                    label='Position'
                    size='small'
                    variant='filled'
                    margin='none'
                    autoComplete='none'
                    InputLabelProps={{ shrink: true }}
                    style={inputStyle}
                    value={state.position}
                    onChange={handleInputChange}
                    InputProps={{ disableUnderline: true }} />

                <CustomFormField
                    name='comments'
                    id='comments'
                    label='Comments'
                    size='small'
                    variant='filled'
                    multiline
                    rows={3}
                    margin='none'
                    required
                    autoComplete='none'
                    InputLabelProps={{ shrink: true }}
                    style={inputStyle}
                    value={state.comments}
                    onChange={handleInputChange}
                    InputProps={{ disableUnderline: true }}
                    {...(state.errors["comments"] && { error: true })}
                />

                <Button
                    variant='contained'
                    onClick={(e) => sendContactForm()}
                    disabled={!captchaStatus || isSending()}
                    endIcon={<SubmitIcon />} >
                    Submit
                </Button>

                <div className='recaptcha'>
                    <ReCAPTCHA
                        sitekey={recaptchaSiteKey}
                        ref={recaptchaRef}
                        size='compact'
                        onChange={captchaPassed} />
                </div>
            </div>
        </div>
    );
};

export default ContactUs;