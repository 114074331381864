import React from 'react';

const ProductSelector = () => {
    return (
        <div className='product-config-select'>
            <h3> </h3>
            <p style={{ marginTop: '35px' }}>
                Contemporary finishes<br /><span>for</span> modern interiors
            </p>
        </div>
    );
};

export default ProductSelector;