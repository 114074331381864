import { graphql, useStaticQuery } from 'gatsby';

const useProductImages = () => {
    const data = useStaticQuery(graphql`
    query {
        allFile(filter: {sourceInstanceName: {eq: "images"}}) {
          edges {
            node {
              extension
              name
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
              dir
              publicURL
            }
          }
        }
      }
    `);

    return data;
};

export default useProductImages;