import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import useProductImages from '../data/ProductImages';

import '../styles/RotarySelector.scss';

export interface RotarySelectorProps {
    selectImage: React.Dispatch<React.SetStateAction<string>>;
    selectedProductFamily: string;
}

const RotarySelector = ({ selectImage, selectedProductFamily }: RotarySelectorProps) => {
    const data = useProductImages();

    const imageSet = data.allFile.edges.filter((f: any, i: number) => f.node.dir.slice(-selectedProductFamily.length) === selectedProductFamily);

    function changeImage(newImage: string) {
        selectImage(newImage);
    }

    return (
        <div className='rotary-container'>
            {imageSet.map((edge: any, i: number) => {
                const srcSet: string = edge.node.childImageSharp.gatsbyImageData.images.sources[0].srcSet;
                const thumbnailSrc = srcSet.slice(0, srcSet.indexOf(' '));

                return (
                    <div key={i} onClick={(e) => changeImage(edge.node.name)} className='product-thumbnail'>
                        <img src={thumbnailSrc} alt={edge.node.name} />
                    </div>
                );
            })}
        </div>
    )
};

export default RotarySelector;