import React from 'react';
import { finish, finishes } from '../data/ProductFinish';
import useProductImages from '../data/ProductImages';

import '../styles/FinishSelector.scss';

export interface FinishSelectorProps {
    setProductFamily: React.Dispatch<React.SetStateAction<string>>;
    setSelectedProduct: React.Dispatch<React.SetStateAction<string>>;
}

const FinishSelector = (props: FinishSelectorProps) => {
    const data = useProductImages();

    function changeImageSet(imageFolder: string) {
        props.setProductFamily(imageFolder);

        const images = data.allFile.edges.filter((f: any, i: number) => f.node.dir.slice(-imageFolder.length) === imageFolder);

        const image = images.filter((f: any, n: number) => f.node.name.includes('AC30'))[0] ?? images[0];
        const firstProduct = image.node.name;

        props.setSelectedProduct(firstProduct);
    }

    return (
        <div className='product-config-colour'>
            <h3>FINISH</h3>
            <ul className='finishes'>
                {finishes.map((v: finish, i: number) => (
                    <li key={i} onClick={(e) => changeImageSet(v.imageFolder)}>
                        <div className='finish-container'>
                            <div className='finish-preview' style={{ backgroundColor: v.colourHex }}>
                            </div>
                        </div>
                        <h3>{v.name.toUpperCase().replace(' ', '\n')}</h3>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FinishSelector;