export const productDescription: { [product: string]: string } = {
    'PCDCL12WW': 'SINGLE LIGHT SWITCH - WIDE ROCKER',
    'PCDCL42WW': 'DOUBLE LIGHT SWITCH - WIDE ROCKER',
    'PCDCL12W': 'SINGLE LIGHT SWITCH',
    'PCDCL13W': 'SINGLE INTERMEDIATE LIGHT SWITCH',
    'PCDCL14W': 'SINGLE PRESS SWITCH',
    'PCDCL15W': 'FAN ISOLATOR SWITCH',
    'PCDCL42W': 'DOUBLE LIGHT SWITCH',
    'PCDCL43W': 'TRIPLE LIGHT SWITCH',
    'PCDCL20W': 'DUAL VOLTAGE SHAVER SOCKET',
    'PCDCL21W': 'SINGLE POWER SOCKET',
    'PCDCL21U2W': 'SINGLE  POWER SOCKET + USB',
    'PCDCL22W': 'DOUBLE POWER SOCKET',
    'PCDCL22U3W': 'DOUBLE POWER SOCKET + USB',
    'PCDCL22UAC30W': 'DOUBLE POWER SOCKET + USB C 30W',
    'PCDCL22UWRW': 'WIFI EXTENDER DOUBLE  SOCKET +  USB',
    'PCDCL31W': '20A SWITCH, DOUBLE POLE - LED INDICATOR',
    'PCDCL52W': 'SWITCHED  FUSED CONNECTION UNIT',
    'PCDCL54W': 'UNSWITCHED FUSED CONNECTION UNIT',
    'PCDCL70W': 'COOKER CONTROL SOCKET -  LED INDICATOR',
    'PCDCL72W': '45A RECTANGULAR SWITCH -  LED INDICATOR',
    'PCDCL74W': '45A SQUARE SWITCH -  LED INDICATOR',
    'PCDCL81W': 'TRAILING EDGE LED 200W SINGLE DIMMER SWITCH',
    'PCDCL82W': 'TRAILING EDGE LED 200W DOUBLE DIMMER SWITCH',
    'PCDCLEMS1W': 'SINGLE EURO MODULE FRONT PLATE (25x50)',
    'PCDCLEMS2W': 'TWIN EURO MODULE  SINGLE FRONT PLATE (50x50)',
    'PCDCLEMR4W': 'QUAD RECTANGULAR FRONT PLATE (100x50)',
    'PCDCLTDM1W': '200W SINGLE TOUCH DIMMER SWITCH - MASTER',
    'PCDCLTDM2W': '200W DOUBLE TOUCH DIMMER SWITCH - MASTER',
    'PCDCLTDS1W': '200W SINGLE TOUCH DIMMER SWITCH-  SECONDARY',
    'PCDCLTDS2W': '200W DOUBLE TOUCH DIMMER SWITCH -  SECONDARY',
    'PCDCL60W': 'SINGLE CO-AXIAL AERIAL SOCKET',
    'PCDCLBTM1W': 'SINGLE MASTER TELEPHONE SOCKET',
    'PCDCLBTS1W': 'SINGLE SECONDARY TELEPHONE SOCKET',
    'PCDCLRJ451W': 'SINGLE RJ45 TELEPHONE SOCKET',
    'PCDBS12WW': 'SINGLE LIGHT SWITCH - WIDE ROCKER',
    'PCDBS42WW': 'DOUBLE LIGHT SWITCH - WIDE ROCKER',
    'PCDBS12W': 'SINGLE LIGHT SWITCH',
    'PCDBS13W': 'SINGLE INTERMEDIATE LIGHT SWITCH',
    'PCDBS14W': 'SINGLE PRESS SWITCH',
    'PCDBS15W': 'FAN ISOLATOR SWITCH',
    'PCDBS42W': 'DOUBLE LIGHT SWITCH',
    'PCDBS43W': 'TRIPLE LIGHT SWITCH',
    'PCDBS20W': 'DUAL VOLTAGE SHAVER SOCKET',
    'PCDBS21W': 'SINGLE POWER SOCKET',
    'PCDBS21U2W': 'SINGLE  POWER SOCKET + USB',
    'PCDBS22W': 'DOUBLE POWER SOCKET',
    'PCDBS22U3W': 'DOUBLE POWER SOCKET + USB',
    'PCDBS22UAC30W': 'DOUBLE POWER SOCKET + USB C 30W',
    'PCDBS22UWRW': 'WIFI EXTENDER DOUBLE  SOCKET +  USB',
    'PCDBS31W': '20A SWITCH, DOUBLE POLE - LED INDICATOR',
    'PCDBS52W': 'SWITCHED  FUSED CONNECTION UNIT',
    'PCDBS54W': 'UNSWITCHED FUSED CONNECTION UNIT',
    'PCDBS70W': 'COOKER CONTROL SOCKET -  LED INDICATOR',
    'PCDBS72W': '45A RECTANGULAR SWITCH -  LED INDICATOR',
    'PCDBS74W': '45A SQUARE SWITCH -  LED INDICATOR',
    'PCDBS81W': 'TRAILING EDGE LED 200W SINGLE DIMMER SWITCH',
    'PCDBS82W': 'TRAILING EDGE LED 200W DOUBLE DIMMER SWITCH',
    'PCDBSEMS1W': 'SINGLE EURO MODULE FRONT PLATE (25x50)',
    'PCDBSEMS2W': 'TWIN EURO MODULE  SINGLE FRONT PLATE (50x50)',
    'PCDBSEMR4W': 'QUAD RECTANGULAR FRONT PLATE (100x50)',
    'PCDBSTDM1W': '200W SINGLE TOUCH DIMMER SWITCH - MASTER',
    'PCDBSTDM2W': '200W DOUBLE TOUCH DIMMER SWITCH - MASTER',
    'PCDBSTDS1W': '200W SINGLE TOUCH DIMMER SWITCH-  SECONDARY',
    'PCDBSTDS2W': '200W DOUBLE TOUCH DIMMER SWITCH -  SECONDARY',
    'PCDBS60W': 'SINGLE CO-AXIAL AERIAL SOCKET',
    'PCDBSBTM1W': 'SINGLE MASTER TELEPHONE SOCKET',
    'PCDBSBTS1W': 'SINGLE SECONDARY TELEPHONE SOCKET',
    'PCDBSRJ451W': 'SINGLE RJ45 TELEPHONE SOCKET',
    'PCDMG12WW': 'SINGLE LIGHT SWITCH - WIDE ROCKER',
    'PCDMG42WB': 'DOUBLE LIGHT SWITCH - WIDE ROCKER',
    'PCDMG12B': 'SINGLE LIGHT SWITCH',
    'PCDMG13B': 'SINGLE INTERMEDIATE LIGHT SWITCH',
    'PCDMG14B': 'SINGLE PRESS SWITCH',
    'PCDMG15B': 'FAN ISOLATOR SWITCH',
    'PCDMG42B': 'DOUBLE LIGHT SWITCH',
    'PCDMG43B': 'TRIPLE LIGHT SWITCH',
    'PCDMG20B': 'DUAL VOLTAGE SHAVER SOCKET',
    'PCDMG21B': 'SINGLE POWER SOCKET',
    'PCDMG21U2B': 'SINGLE  POWER SOCKET + USB',
    'PCDMG22B': 'DOUBLE POWER SOCKET',
    'PCDMG22U3B': 'DOUBLE POWER SOCKET + USB',
    'PCDMG22UAC30B': 'DOUBLE POWER SOCKET + USB C 30W',
    'PCDMG22UWRB': 'WIFI EXTENDER DOUBLE  SOCKET +  USB',
    'PCDMG31B': '20A SWITCH, DOUBLE POLE - LED INDICATOR',
    'PCDMG52B': 'SWITCHED  FUSED CONNECTION UNIT',
    'PCDMG54B': 'UNSWITCHED FUSED CONNECTION UNIT',
    'PCDMG70B': 'COOKER CONTROL SOCKET -  LED INDICATOR',
    'PCDMG72B': '45A RECTANGULAR SWITCH -  LED INDICATOR',
    'PCDMG74B': '45A SQUARE SWITCH -  LED INDICATOR',
    'PCDMG81B': 'TRAILING EDGE LED 200W SINGLE DIMMER SWITCH',
    'PCDMG82B': 'TRAILING EDGE LED 200W DOUBLE DIMMER SWITCH',
    'PCDMGEMS1B': 'SINGLE EURO MODULE FRONT PLATE (25x50)',
    'PCDMGEMS2B': 'TWIN EURO MODULE  SINGLE FRONT PLATE (50x50)',
    'PCDMGEMR4B': 'QUAD RECTANGULAR FRONT PLATE (100x50)',
    'PCDMGTDM1B': '200W SINGLE TOUCH DIMMER SWITCH - MASTER',
    'PCDMGTDM2B': '200W DOUBLE TOUCH DIMMER SWITCH - MASTER',
    'PCDMGTDS1B': '200W SINGLE TOUCH DIMMER SWITCH-  SECONDARY',
    'PCDMGTDS2B': '200W DOUBLE TOUCH DIMMER SWITCH -  SECONDARY',
    'PCDMG60B': 'SINGLE CO-AXIAL AERIAL SOCKET',
    'PCDMGBTM1B': 'SINGLE MASTER TELEPHONE SOCKET',
    'PCDMGBTS1B': 'SINGLE SECONDARY TELEPHONE SOCKET',
    'PCDMGRJ451B': 'SINGLE RJ45 TELEPHONE SOCKET',
    'PCDMB12WB': 'SINGLE LIGHT SWITCH - WIDE ROCKER',
    'PCDMB42WB': 'DOUBLE LIGHT SWITCH - WIDE ROCKER',
    'PCDMB12B': 'SINGLE LIGHT SWITCH',
    'PCDMB13B': 'SINGLE INTERMEDIATE LIGHT SWITCH',
    'PCDMB14B': 'SINGLE PRESS SWITCH',
    'PCDMB15B': 'FAN ISOLATOR SWITCH',
    'PCDMB42B': 'DOUBLE LIGHT SWITCH',
    'PCDMB43B': 'TRIPLE LIGHT SWITCH',
    'PCDMB20B': 'DUAL VOLTAGE SHAVER SOCKET',
    'PCDMB21B': 'SINGLE POWER SOCKET',
    'PCDMB21U2B': 'SINGLE  POWER SOCKET + USB',
    'PCDMB22B': 'DOUBLE POWER SOCKET',
    'PCDMB22U3B': 'DOUBLE POWER SOCKET + USB',
    'PCDMB22UAC30B': 'DOUBLE POWER SOCKET + USB C 30W',
    'PCDMB22UWRB': 'WIFI EXTENDER DOUBLE  SOCKET +  USB',
    'PCDMB31B': '20A SWITCH, DOUBLE POLE - LED INDICATOR',
    'PCDMB52B': 'SWITCHED  FUSED CONNECTION UNIT',
    'PCDMB54B': 'UNSWITCHED FUSED CONNECTION UNIT',
    'PCDMB70B': 'COOKER CONTROL SOCKET -  LED INDICATOR',
    'PCDMB72B': '45A RECTANGULAR SWITCH -  LED INDICATOR',
    'PCDMB74B': '45A SQUARE SWITCH -  LED INDICATOR',
    'PCDMB81B': 'TRAILING EDGE LED 200W SINGLE DIMMER SWITCH',
    'PCDMB82B': 'TRAILING EDGE LED 200W DOUBLE DIMMER SWITCH',
    'PCDMBEMS1B': 'SINGLE EURO MODULE FRONT PLATE (25x50)',
    'PCDMBEMS2B': 'TWIN EURO MODULE  SINGLE FRONT PLATE (50x50)',
    'PCDMBEMR4B': 'QUAD RECTANGULAR FRONT PLATE (100x50)',
    'PCDMBTDM1B': '200W SINGLE TOUCH DIMMER SWITCH - MASTER',
    'PCDMBTDM2B': '200W DOUBLE TOUCH DIMMER SWITCH - MASTER',
    'PCDMBTDS1B': '200W SINGLE TOUCH DIMMER SWITCH-  SECONDARY',
    'PCDMBTDS2B': '200W DOUBLE TOUCH DIMMER SWITCH -  SECONDARY',
    'PCDMB60B': 'SINGLE CO-AXIAL AERIAL SOCKET',
    'PCDMBBTM1B': 'SINGLE MASTER TELEPHONE SOCKET',
    'PCDMBBTS1B': 'SINGLE SECONDARY TELEPHONE SOCKET',
    'PCDMBRJ451B': 'SINGLE RJ45 TELEPHONE SOCKET',
    'PCDDB12WB': 'SINGLE LIGHT SWITCH - WIDE ROCKER',
    'PCDDB42WB': 'DOUBLE LIGHT SWITCH - WIDE ROCKER',
    'PCDDB12B': 'SINGLE LIGHT SWITCH',
    'PCDDB13B': 'SINGLE INTERMEDIATE LIGHT SWITCH',
    'PCDDB14B': 'SINGLE PRESS SWITCH',
    'PCDDB15B': 'FAN ISOLATOR SWITCH',
    'PCDDB42B': 'DOUBLE LIGHT SWITCH',
    'PCDDB43B': 'TRIPLE LIGHT SWITCH',
    'PCDDB20B': 'DUAL VOLTAGE SHAVER SOCKET',
    'PCDDB21B': 'SINGLE POWER SOCKET',
    'PCDDB21U2B': 'SINGLE  POWER SOCKET + USB',
    'PCDDB22B': 'DOUBLE POWER SOCKET',
    'PCDDB22U3B': 'DOUBLE POWER SOCKET + USB',
    'PCDDB22UAC30B': 'DOUBLE POWER SOCKET + USB C 30W',
    'PCDDB22UWRB': 'WIFI EXTENDER DOUBLE  SOCKET +  USB',
    'PCDDB31B': '20A SWITCH, DOUBLE POLE - LED INDICATOR',
    'PCDDB52B': 'SWITCHED  FUSED CONNECTION UNIT',
    'PCDDB54B': 'UNSWITCHED FUSED CONNECTION UNIT',
    'PCDDB70B': 'COOKER CONTROL SOCKET -  LED INDICATOR',
    'PCDDB72B': '45A RECTANGULAR SWITCH -  LED INDICATOR',
    'PCDDB74B': '45A SQUARE SWITCH -  LED INDICATOR',
    'PCDDB81B': 'TRAILING EDGE LED 200W SINGLE DIMMER SWITCH',
    'PCDDB82B': 'TRAILING EDGE LED 200W DOUBLE DIMMER SWITCH',
    'PCDDBEMS1B': 'SINGLE EURO MODULE FRONT PLATE (25x50)',
    'PCDDBEMS2B': 'TWIN EURO MODULE  SINGLE FRONT PLATE (50x50)',
    'PCDDBEMR4B': 'QUAD RECTANGULAR FRONT PLATE (100x50)',
    'PCDDBTDM1B': '200W SINGLE TOUCH DIMMER SWITCH - MASTER',
    'PCDDBTDM2B': '200W DOUBLE TOUCH DIMMER SWITCH - MASTER',
    'PCDDBTDS1B': '200W SINGLE TOUCH DIMMER SWITCH-  SECONDARY',
    'PCDDBTDS2B': '200W DOUBLE TOUCH DIMMER SWITCH -  SECONDARY',
    'PCDDB60B': 'SINGLE CO-AXIAL AERIAL SOCKET',
    'PCDDBBTM1B': 'SINGLE MASTER TELEPHONE SOCKET',
    'PCDDBBTS1B': 'SINGLE SECONDARY TELEPHONE SOCKET',
    'PCDDBRJ451B': 'SINGLE RJ45 TELEPHONE SOCKET',
    'PCDCP12WB': 'SINGLE LIGHT SWITCH - WIDE ROCKER',
    'PCDCP42WB': 'DOUBLE LIGHT SWITCH - WIDE ROCKER',
    'PCDCP12B': 'SINGLE LIGHT SWITCH',
    'PCDCP13B': 'SINGLE INTERMEDIATE LIGHT SWITCH',
    'PCDCP14B': 'SINGLE PRESS SWITCH',
    'PCDCP15B': 'FAN ISOLATOR SWITCH',
    'PCDCP42B': 'DOUBLE LIGHT SWITCH',
    'PCDCP43B': 'TRIPLE LIGHT SWITCH',
    'PCDCP20B': 'DUAL VOLTAGE SHAVER SOCKET',
    'PCDCP21B': 'SINGLE POWER SOCKET',
    'PCDCP21U2B': 'SINGLE  POWER SOCKET + USB',
    'PCDCP22B': 'DOUBLE POWER SOCKET',
    'PCDCP22U3B': 'DOUBLE POWER SOCKET + USB',
    'PCDCP22UAC30B': 'DOUBLE POWER SOCKET + USB C 30W',
    'PCDCP22UWRB': 'WIFI EXTENDER DOUBLE  SOCKET +  USB',
    'PCDCP31B': '20A SWITCH, DOUBLE POLE - LED INDICATOR',
    'PCDCP52B': 'SWITCHED  FUSED CONNECTION UNIT',
    'PCDCP54B': 'UNSWITCHED FUSED CONNECTION UNIT',
    'PCDCP70B': 'COOKER CONTROL SOCKET -  LED INDICATOR',
    'PCDCP72B': '45A RECTANGULAR SWITCH -  LED INDICATOR',
    'PCDCP74B': '45A SQUARE SWITCH -  LED INDICATOR',
    'PCDCP81B': 'TRAILING EDGE LED 200W SINGLE DIMMER SWITCH',
    'PCDCP82B': 'TRAILING EDGE LED 200W DOUBLE DIMMER SWITCH',
    'PCDCPEMS1B': 'SINGLE EURO MODULE FRONT PLATE (25x50)',
    'PCDCPEMS2B': 'TWIN EURO MODULE  SINGLE FRONT PLATE (50x50)',
    'PCDCPEMR4B': 'QUAD RECTANGULAR FRONT PLATE (100x50)',
    'PCDCPTDM1B': '200W SINGLE TOUCH DIMMER SWITCH - MASTER',
    'PCDCPTDM2B': '200W DOUBLE TOUCH DIMMER SWITCH - MASTER',
    'PCDCPTDS1B': '200W SINGLE TOUCH DIMMER SWITCH-  SECONDARY',
    'PCDCPTDS2B': '200W DOUBLE TOUCH DIMMER SWITCH -  SECONDARY',
    'PCDCP60B': 'SINGLE CO-AXIAL AERIAL SOCKET',
    'PCDCPBTM1B': 'SINGLE MASTER TELEPHONE SOCKET',
    'PCDCPBTS1B': 'SINGLE SECONDARY TELEPHONE SOCKET',
    'PCDCPRJ451B': 'SINGLE RJ45 TELEPHONE SOCKET',
    'PCDSB12WB': 'SINGLE LIGHT SWITCH - WIDE ROCKER',
    'PCDSB42WB': 'DOUBLE LIGHT SWITCH - WIDE ROCKER',
    'PCDSB12B': 'SINGLE LIGHT SWITCH',
    'PCDSB13B': 'SINGLE INTERMEDIATE LIGHT SWITCH',
    'PCDSB14B': 'SINGLE PRESS SWITCH',
    'PCDSB15B': 'FAN ISOLATOR SWITCH',
    'PCDSB42B': 'DOUBLE LIGHT SWITCH',
    'PCDSB43B': 'TRIPLE LIGHT SWITCH',
    'PCDSB20B': 'DUAL VOLTAGE SHAVER SOCKET',
    'PCDSB21B': 'SINGLE POWER SOCKET',
    'PCDSB21U2B': 'SINGLE  POWER SOCKET + USB',
    'PCDSB22B': 'DOUBLE POWER SOCKET',
    'PCDSB22U3B': 'DOUBLE POWER SOCKET + USB',
    'PCDSB22UAC30B': 'DOUBLE POWER SOCKET + USB C 30W',
    'PCDSB22UWRB': 'WIFI EXTENDER DOUBLE  SOCKET +  USB',
    'PCDSB31B': '20A SWITCH, DOUBLE POLE - LED INDICATOR',
    'PCDSB52B': 'SWITCHED  FUSED CONNECTION UNIT',
    'PCDSB54B': 'UNSWITCHED FUSED CONNECTION UNIT',
    'PCDSB70B': 'COOKER CONTROL SOCKET -  LED INDICATOR',
    'PCDSB72B': '45A RECTANGULAR SWITCH -  LED INDICATOR',
    'PCDSB74B': '45A SQUARE SWITCH -  LED INDICATOR',
    'PCDSB81B': 'TRAILING EDGE LED 200W SINGLE DIMMER SWITCH',
    'PCDSB82B': 'TRAILING EDGE LED 200W DOUBLE DIMMER SWITCH',
    'PCDSBEMS1B': 'SINGLE EURO MODULE FRONT PLATE (25x50)',
    'PCDSBEMS2B': 'TWIN EURO MODULE  SINGLE FRONT PLATE (50x50)',
    'PCDSBEMR4B': 'QUAD RECTANGULAR FRONT PLATE (100x50)',
    'PCDSBTDM1B': '200W SINGLE TOUCH DIMMER SWITCH - MASTER',
    'PCDSBTDM2B': '200W DOUBLE TOUCH DIMMER SWITCH - MASTER',
    'PCDSBTDS1B': '200W SINGLE TOUCH DIMMER SWITCH-  SECONDARY',
    'PCDSBTDS2B': '200W DOUBLE TOUCH DIMMER SWITCH -  SECONDARY',
    'PCDSB60B': 'SINGLE CO-AXIAL AERIAL SOCKET',
    'PCDSBBTM1B': 'SINGLE MASTER TELEPHONE SOCKET',
    'PCDSBBTS1B': 'SINGLE SECONDARY TELEPHONE SOCKET',
    'PCDSBRJ451B': 'SINGLE RJ45 TELEPHONE SOCKET',
    'PCDBC12WB': 'SINGLE LIGHT SWITCH - WIDE ROCKER',
    'PCDBC42WB': 'DOUBLE LIGHT SWITCH - WIDE ROCKER',
    'PCDBC12B': 'SINGLE LIGHT SWITCH',
    'PCDBC13B': 'SINGLE INTERMEDIATE LIGHT SWITCH',
    'PCDBC14B': 'SINGLE PRESS SWITCH',
    'PCDBC15B': 'FAN ISOLATOR SWITCH',
    'PCDBC42B': 'DOUBLE LIGHT SWITCH',
    'PCDBC43B': 'TRIPLE LIGHT SWITCH',
    'PCDBC20B': 'DUAL VOLTAGE SHAVER SOCKET',
    'PCDBC21B': 'SINGLE POWER SOCKET',
    'PCDBC21U2B': 'SINGLE  POWER SOCKET + USB',
    'PCDBC22B': 'DOUBLE POWER SOCKET',
    'PCDBC22U3B': 'DOUBLE POWER SOCKET + USB',
    'PCDBC22UAC30B': 'DOUBLE POWER SOCKET + USB C 30W',
    'PCDBC22UWRB': 'WIFI EXTENDER DOUBLE  SOCKET +  USB',
    'PCDBC31B': '20A SWITCH, DOUBLE POLE - LED INDICATOR',
    'PCDBC52B': 'SWITCHED  FUSED CONNECTION UNIT',
    'PCDBC54B': 'UNSWITCHED FUSED CONNECTION UNIT',
    'PCDBC70B': 'COOKER CONTROL SOCKET -  LED INDICATOR',
    'PCDBC72B': '45A RECTANGULAR SWITCH -  LED INDICATOR',
    'PCDBC74B': '45A SQUARE SWITCH -  LED INDICATOR',
    'PCDBC81B': 'TRAILING EDGE LED 200W SINGLE DIMMER SWITCH',
    'PCDBC82B': 'TRAILING EDGE LED 200W DOUBLE DIMMER SWITCH',
    'PCDBCEMS1B': 'SINGLE EURO MODULE FRONT PLATE (25x50)',
    'PCDBCEMS2B': 'TWIN EURO MODULE  SINGLE FRONT PLATE (50x50)',
    'PCDBCEMR4B': 'QUAD RECTANGULAR FRONT PLATE (100x50)',
    'PCDBCTDM1B': '200W SINGLE TOUCH DIMMER SWITCH - MASTER',
    'PCDBCTDM2B': '200W DOUBLE TOUCH DIMMER SWITCH - MASTER',
    'PCDBCTDS1B': '200W SINGLE TOUCH DIMMER SWITCH-  SECONDARY',
    'PCDBCTDS2B': '200W DOUBLE TOUCH DIMMER SWITCH -  SECONDARY',
    'PCDBC60B': 'SINGLE CO-AXIAL AERIAL SOCKET',
    'PCDBCBTM1B': 'SINGLE MASTER TELEPHONE SOCKET',
    'PCDBCBTS1B': 'SINGLE SECONDARY TELEPHONE SOCKET',
    'PCDBCRJ451B': 'SINGLE RJ45 TELEPHONE SOCKET',
};